export const challenges = [
  {
    name: "Nice",
    description: "Reach Coords 6969 69 6969",
    submission:
      "Post a screenshot of your entire team together at these coords",
  },
  {
    name: "Die of Shame",
    description:
      "You must die to the following causes: Pufferfish, Starvation, Suffocation, Intentional Game Design, Squashed, Impaled, Freezing and Kinetic Energy",
    submission:
      "Post screenshots of at least one of your team members achieving each death message",
  },
  {
    name: "Falling to Earth",
    description: "Fall from overworld world height to bedrock without dying",
    submission:
      "Post a video clip of your entire team doing this at the same time",
  },
  {
    name: "Star Trader",
    description:
      "Trade with a villager while standing at build height limit in the overworld",
    submission:
      "Post a screenshot of your team with the villager at the build height limit with emeralds in your hands",
  },
  {
    name: "It's a Sign",
    description:
      "Place 16 of each type of sign, each with a different color and using glow ink sacks.",
    submission: "Post a screenshot of the sign wall",
  },
  {
    name: "10/10",
    description:
      "Earn any 10 advancements within a 10 minute period (this will require some planning)",
    submission:
      "Post a video clip of one of your team achieving 10 advancements within 10 minutes",
  },
  {
    name: "Gear Up",
    description: "Get every set of armour and display them on armour stands",
    submission: "Post a screenshot of the armour stands",
  },
  {
    name: "Epic Feast",
    description: "Get one of every single food in the game (there are 41)",
    submission: "Post a screenshots of all the food items",
  },
  {
    name: "Valuable Items",
    description:
      "Fill a double chest up with golden hoes, because they are the most important item in the game",
    submission: "Post a screenshot of the golden hoes in the chest",
  },
  {
    name: "Rainbow",
    description: "Get one of each coloured sheep, glass, concrete and shulker",
    submission: "Post a screenshot of all the items",
  },
  {
    name: "Endgame",
    description: "Defeat the enderdragon",
    submission:
      "Post a screenshot of the dragon death animation (teams can share a single fight for this submission)",
  },
  {
    name: "mOre",
    description: "Mine a stack of every ore (silk touch is required)",
    submission: "Post a screenshot of all the ore",
  },
  {
    name: "Diggy Diggy Hole",
    description: "Dig out 1 chunk (16x16) ",
    submission: "Post a screenshot of the hole (and coords)",
  },
  {
    name: "Barrel of laughs",
    description: "Fill a barrel with barrels",
    submission: "Post a screenshot of all the barrels",
  },
  {
    name: "When pigs fly",
    description: "Get the former advancement 'When Pigs Fly'",
    submission:
      "Post a video clip of you jumping of a 5 block tall platform while riding a pig",
  },
  {
    name: "A decent brew",
    description:
      "Brew one of every brewable potions in the game (there are 15)",
    submission: "Post a screenshot of the potions",
  },
  {
    name: "I can breath!",
    description: "Build a working conduit",
    submission: "Post a screenshot of the workign conduit",
  },
  {
    name: "Do you like jazz?",
    description: "Get every music disc in the game (there are 14)",
    submission: "Post a screenshot of all the music discs",
  },
  {
    name: "Mine turtles",
    description: "Get a stack of turtle eggs",
    submission: "Post a screenshot of the turtle eggs",
  },
  {
    name: "The beginning?",
    description: "Defeat the wither",
    submission:
      "Post a video clip of the fight or a screenshot of the nether star",
  },
  {
    name: "Fabulous Flora",
    description: "Get at least 1 of each flower in the game (there are 17)",
    submission: "Post a screenshot of the flowers",
  },
  {
    name: "Busy Bees",
    description: "Get a stack of honey blocks and slide down them",
    submission:
      "Post a screenshot of the honey blocks in your inventory, and placed down",
  },
  {
    name: "Classic god gear",
    description:
      "Enchant all 4 diamond tools with unbreaking 3, efficiency 5, a sword with sharpness 5 and unbreaking 3 and all 4 pieces of armour with unbreaking 3 and protection 4, per team member",
    submission:
      "Post a screenshot of your entire team with these items at the same time",
  },
  {
    name: "Quarantine Zone",
    description:
      "Encase a naturally spawning village in a glass cube (you must cover all the houses in the same cube)",
    submission: "Post a screenshot of the glass cube",
  },
  {
    name: "Sticky Situation",
    description:
      "Fill a barrel with shulker boxes that are all full of stacks of sticks",
    submission: "Post a screenshot of the shulker box",
  },
];
