type Team = {
  name: string;
  completed: number[];
};

export const teams: Team[] = [
  {
    name: "Team OiOi",
    completed: [1, 5, 8, 13, 14],
  },
  {
    name: "RnR",
    completed: [
      1, 2, 3, 4, 5, 6, 8, 9, 10, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23, 24,
    ],
  },
  {
    name: "Spoon Bois",
    completed: [5, 8, 14, 18],
  },
  {
    name: "Tof",
    completed: [
      0, 1, 2, 3, 4, 5, 8, 9, 10, 12, 13, 14, 16, 18, 19, 20, 21, 22, 24,
    ],
  },
];
