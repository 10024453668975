import "./styles/App.scss";
import background from "./img/background.png";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Bingo } from "./pages/bingo/Bingo";

function App() {
  return (
    <div className="wrapper" style={{ backgroundImage: `url(${background})` }}>
      <BrowserRouter>
        <Routes>
          <Route path="/bingo" element={<Bingo />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
