/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Card } from "./Card";
import { teams } from "./Teams";

export function Bingo() {
  const [dropdown, setDropdown] = useState(0);
  const [tab, setTab] = useState(-1);

  return (
    <>
      <div className="bingo">
        <h1>MiniPixel Bingo #1</h1>
        Ends on 27/02/2022 @ 6PM GMT
        <a href="#" className="button">
          IP: events.minipixel.net
        </a>
        <DropdownSection
          title="What is it?"
          content={
            <p>
              MiniPixel Bingo is a longer challenge event in which you must
              complete various challenges on a card as a team of up to 4
              players. This first event will last 1 week with the server up
              24/7.
            </p>
          }
          open={dropdown === 1}
          onTrigger={() => setDropdown(dropdown === 1 ? 0 : 1)}
        />
        <DropdownSection
          title="How do I play?"
          content={
            <p>
              Once the server opens you and your team must join the server and
              complete all the challenges listed below, in any order. You must
              provide proof of completion, this can be submitted in the #bingo
              channel on discord and tagging the staff team. Valid proof
              inclutes screenshots, videos or showing staff ingame. (Some
              challenges might require more specific proof of completion).{" "}
              <strong>
                To avoid cheating, moderators may ask to see your proof ingame
                and once redeemed, any items will be destroyed.
              </strong>
            </p>
          }
          open={dropdown === 2}
          onTrigger={() => setDropdown(dropdown === 2 ? 0 : 2)}
        />
        <DropdownSection
          title="Are there prizes?"
          content={
            <p>
              While the main prize is bragging rights, the winning team will
              each receive the coveted minipixel events golden star tag to show
              next to your name on minipixel servers, as well as some additional
              rewards on our upcoming survival/mmo server.
            </p>
          }
          open={dropdown === 3}
          onTrigger={() => setDropdown(dropdown === 3 ? 0 : 3)}
        />
        <div className="cards">
          <h2>Bingo Card</h2>
          <p>
            Leaderboard updated at 6PM GMT every day.
            <br />
            <strong>Current Leader:</strong> RnR with 20 Completions
          </p>
          <div className="button-array">
            <button
              onClick={() => setTab(-1)}
              className={tab === -1 ? "selected" : ""}
            >
              Blank Card
            </button>
            {teams.map((team, index) => (
              <button
                key={index}
                className={tab === index ? "selected" : ""}
                onClick={() => setTab(index)}
              >
                {team.name}
              </button>
            ))}
          </div>
          <Card tab={tab} />
        </div>
      </div>
    </>
  );
}

type DropdownSelection = {
  title: string;
  content: JSX.Element;
  open: boolean;
  onTrigger: VoidFunction;
};

function DropdownSection({
  title,
  content,
  open,
  onTrigger,
}: DropdownSelection) {
  return (
    <div className="dropdown-section">
      <h3 className="trigger" onClick={onTrigger}>
        <FontAwesomeIcon
          icon={open ? faChevronDown : faChevronRight}
          className="arrow"
        />
        <strong>{title}</strong>
      </h3>
      <p>{open && <>{content}</>}</p>
    </div>
  );
}
