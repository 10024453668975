import { useState } from "react";
import { challenges } from "./Challenges";
import { teams } from "./Teams";

type Challenge = {
  name: string;
  description: string;
  submission: string;
};

type CardProps = {
  tab: number;
};

export function Card({ tab }: CardProps) {
  const [tooltip, setTooltip] = useState(-1);
  const team = tab > -1 ? teams[tab] : null;

  function renderChallenge(card: Challenge, index: number) {
    return (
      <div
        className="card-container"
        key={index}
        onMouseEnter={() => setTooltip(index)}
        onMouseLeave={() => setTooltip(-1)}
        onTouchStart={() => setTooltip(index)}
        onTouchEnd={() => setTooltip(-1)}
      >
        <div className="card">
          {card.name}
          {team && team.completed.indexOf(index) > -1 && (
            <p className="completed">Complete</p>
          )}
        </div>
        {index === tooltip && (
          <div className="tooltip">
            <strong>Task</strong>
            <br /> {card.description}
            <br />
            <br />
            <strong>Submission</strong>
            <br /> {card.submission}
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="card-row">
        {challenges
          .slice(0, 5)
          .map((challenge, index) => renderChallenge(challenge, index))}
      </div>
      <div className="card-row">
        {challenges
          .slice(5, 10)
          .map((challenge, index) => renderChallenge(challenge, 5 + index))}
      </div>
      <div className="card-row">
        {challenges
          .slice(10, 15)
          .map((challenge, index) => renderChallenge(challenge, 10 + index))}
      </div>
      <div className="card-row">
        {challenges
          .slice(15, 20)
          .map((challenge, index) => renderChallenge(challenge, 15 + index))}
      </div>
      <div className="card-row">
        {challenges
          .slice(20, 25)
          .map((challenge, index) => renderChallenge(challenge, 20 + index))}
      </div>
    </>
  );
}
