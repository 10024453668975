import logo from "../img/logo.png";

export function Home() {
  return (
    <div className="home">
      <div className="content">
        <img src={logo} className="logo" alt="logo" />
        <br />
        <h1>MiniPixel</h1>
        <p>
          MiniPixel is an upcoming Minecraft server with a unique blend of
          survival and mmo content. Join us in discord to receive updates and
          sneak peeks!
        </p>
        <a
          href="https://discord.gg/EhshkpJ"
          className="discord"
          target="_blank"
          rel="noreferrer"
        >
          Join the Discord
        </a>
      </div>
    </div>
  );
}
